import isAdmin from "@/middlewares/is-admin";
import isSuperAdmin from "@/middlewares/is-superadmin";

const AdminLayout = () => import("@/views/layouts/Admin");

const Restaurants = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/Restaurants"
  );
const RestaurantForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/RestaurantForm"
  );
const Timetables = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/Timetables.vue"
  );
const TimetableForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/TimetableForm.vue"
  );
const Toppings = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/Toppings.vue"
  );
const ToppingForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/ToppingForm.vue"
  );
const ToppingsAddit = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/ToppingsAddit.vue"
  );
const ToppingAdditForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/ToppingAdditForm.vue"
  );
const Modifications = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/Modifications.vue"
  );
const ModificationForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/ModificationForm.vue"
  );
const Accompaniments = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/Accompaniments.vue"
  );
const AccompanimentForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/AccompanimentForm.vue"
  );
const Sections = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/Sections.vue"
  );
const SectionForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/SectionForm.vue"
  );
const Dishes = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/sections/Dishes.vue"
  );
const DishForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/sections/DishForm.vue"
  );
const Sliders = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/Sliders.vue"
  );
const SliderForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/SliderForm.vue"
  );
const Splashes = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/Splashes.vue"
  );
const SplashForm = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/SplashForm.vue"
  );
const DeletedItems = () =>
  import(
    /* webpackChunkName: "admin-restaurants" */ "@/views/admin/restaurants/DeletedItems.vue"
  );
const Cuisines = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/Cuisines.vue"
  );
const CuisineForm = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/CuisineForm.vue"
  );
const Filters = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/Filters.vue"
  );
const FilterForm = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/FilterForm.vue"
  );
const Taxes = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/Taxes.vue"
  );
const TaxForm = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/TaxForm.vue"
  );
const Types = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/Types.vue"
  );
const TypeForm = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/TypeForm.vue"
  );
const Packages = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/Packages.vue"
  );
const PackageForm = () =>
  import(
    /* webpackChunkName: "admin-catalogue" */ "@/views/admin/catalogue/PackageForm.vue"
  );
const Orders = () =>
  import(/* webpackChunkName: "admin-other" */ "@/views/admin/Orders.vue");
const OrderForm = () =>
  import(/* webpackChunkName: "admin-other" */ "@/views/admin/OrderForm.vue");
const Users = () =>
  import(/* webpackChunkName: "admin-other" */ "@/views/admin/Users.vue");
const UserForm = () =>
  import(/* webpackChunkName: "admin-other" */ "@/views/admin/UserForm.vue");
const UserNewOrder = () =>
  import(
    /* webpackChunkName: "admin-other" */ "@/views/admin/UserNewOrder.vue"
  );
const Couriers = () =>
  import(/* webpackChunkName: "admin-other" */ "@/views/admin/Couriers.vue");
const CourierForm = () =>
  import(/* webpackChunkName: "admin-other" */ "@/views/admin/CourierForm.vue");
const Intervals = () =>
  import(/* webpackChunkName: "admin-other" */ "@/views/admin/Intervals.vue");
const StatisticsRestaurants = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/Restaurants.vue"
  );
const StatisticsPayRestaurants = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/payRestaurants.vue"
  );
const StatisticsGlobal = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/Global.vue"
  );
const StatisticsDishesRest = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/DishesRest.vue"
  );
const StatisticsOrders = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/Orders.vue"
  );
const StatisticsCoupons = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Coupons.vue"
  );
const StatisticsBalances = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Balances.vue"
  );
const StatisticsUserBalances = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/userBalances.vue"
  );
const StatisticsUserBalancesOnDate = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/userBalancesOnDate.vue"
  );
const StatisticsTips = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/Tips.vue"
  );
const StatisticsAmex = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/Amex.vue"
  );
const StatisticsPackageBalances = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/PackageBalances.vue"
  );
const StatisticsAccounting = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/Accounting.vue"
  );
const StatisticsGeneral = () =>
  import(
    /* webpackChunkName: "admin-statistics" */ "@/views/accountant/statistics/General.vue"
  );
const StatisticsPurchases = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/Purchases.vue"
  );
const StatisticsPurchasedOrders = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/PurchasedOrders.vue"
  );
const StatisticsDeliveredOrders = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/DeliveredOrders.vue"
  );
const StatisticsCancelledOrders = () =>
  import(
    /* webpackChunkName: "accountant-statistics" */ "@/views/accountant/statistics/CancelledOrders.vue"
  );
const ExtraShopCategories = () =>
  import(
    /* webpackChunkName: "admin-extra-shop" */ "@/views/admin/ExtraShopCategories.vue"
  );
const ExtraShopCategoryForm = () =>
  import(
    /* webpackChunkName: "admin-extra-shop" */ "@/views/admin/ExtraShopCategoryForm.vue"
  );
const ExtraShopDishes = () =>
  import(
    /* webpackChunkName: "admin-extra-shop" */ "@/views/admin/extra-shop/Dishes.vue"
  );
const ExtraShopDishForm = () =>
  import(
    /* webpackChunkName: "admin-extra-shop" */ "@/views/admin/extra-shop/DishForm.vue"
  );
const Coupons = () =>
  import(/* webpackChunkName: "admin-coupons" */ "@/views/admin/Coupons.vue");
const CouponsForm = () =>
  import(
    /* webpackChunkName: "admin-coupons" */ "@/views/admin/CouponsForm.vue"
  );
const PackageBalances = () =>
  import(
    /* webpackChunkName: "admin-package-balances" */ "@/views/admin/PackageBalances.vue"
  );
const PackageBalanceForm = () =>
  import(
    /* webpackChunkName: "admin-package-balances" */ "@/views/admin/PackageBalanceForm.vue"
  );
const NotificationsDishes = () =>
    import(
        /* webpackChunkName: "admin-notifications" */ "@/views/admin/notifications/Dishes.vue"
        );
const NotificationsRestaurants = () =>
    import(
        /* webpackChunkName: "admin-notifications" */ "@/views/admin/notifications/Restaurants.vue"
        );
const NotificationsStock = () =>
  import(
    /* webpackChunkName: "admin-notifications" */ "@/views/admin/notifications/Stock.vue"
  );
const SettingsGeneral = () =>
  import(
    /* webpackChunkName: "admin-settings" */ "@/views/admin/settings/SettingsGeneral.vue"
  );
const SettingsHero = () =>
  import(
    /* webpackChunkName: "admin-settings" */ "@/views/admin/settings/SettingsHero.vue"
  );
const SettingsSlider = () =>
  import(
    /* webpackChunkName: "admin-settings" */ "@/views/admin/settings/SettingsSlider.vue"
  );
const SettingsSliderForm = () =>
  import(
    /* webpackChunkName: "admin-settings" */ "@/views/admin/settings/SettingsSliderForm.vue"
  );

export default [
  {
    path: "/admin",
    name: "admin",
    component: AdminLayout,
    redirect: { name: "admin.restaurants" },
    meta: {
      breadcrumb: "admin.title",
      middleware: [isAdmin]
    },
    children: [
      {
        path: "restaurants",
        meta: {
          breadcrumb: "admin.restaurants.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.restaurants",
            component: Restaurants,
            meta: {
              breadcrumb: "admin.restaurants.title"
            }
          },
          {
            path: "create",
            name: "admin.restaurants.create",
            component: RestaurantForm,
            meta: {
              breadcrumb: "admin.restaurants.create.title"
            }
          },
          {
            path: ":restaurant/edit",
            name: "admin.restaurants.edit",
            component: RestaurantForm,
            meta: {
              breadcrumb: "admin.restaurants.edit.title",
              edit: true
            }
          },

          // Timetables
          {
            path: ":restaurant/timetables",
            meta: {
              breadcrumb: "admin.restaurants.timetables.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.restaurants.timetables",
                redirect: { name: "admin.restaurants.timetables.delivery" }
              },
              {
                path: "delivery",
                name: "admin.restaurants.timetables.delivery",
                component: Timetables,
                meta: {
                  breadcrumb: "admin.delivery"
                }
              },
              {
                path: "pickup",
                name: "admin.restaurants.timetables.pickup",
                component: Timetables,
                meta: {
                  breadcrumb: "admin.pickup",
                  pickup: true
                }
              },
              {
                path: "delivery/create",
                name: "admin.restaurants.timetables.delivery.create",
                component: TimetableForm,
                meta: {
                  breadcrumb:
                    "admin.restaurants.timetables.delivery.create.title"
                }
              },
              {
                path: "pickup/create",
                name: "admin.restaurants.timetables.pickup.create",
                component: TimetableForm,
                meta: {
                  breadcrumb:
                    "admin.restaurants.timetables.pickup.create.title",
                  pickup: true
                }
              },
              {
                path: ":timetable/edit",
                name: "admin.restaurants.timetables.edit",
                component: TimetableForm,
                meta: {
                  breadcrumb: "admin.restaurants.timetables.edit.title",
                  edit: true
                }
              }
            ]
          },

          // Toppings
          {
            path: ":restaurant/toppings",
            meta: {
              breadcrumb: "admin.restaurants.toppings.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.restaurants.toppings",
                component: Toppings,
                meta: {
                  breadcrumb: "admin.restaurants.toppings.title"
                }
              },
              {
                path: "create",
                name: "admin.restaurants.toppings.create",
                component: ToppingForm,
                meta: {
                  breadcrumb: "admin.restaurants.toppings.create.title"
                }
              },
              {
                path: ":topping/edit",
                name: "admin.restaurants.toppings.edit",
                component: ToppingForm,
                meta: {
                  breadcrumb: "admin.restaurants.toppings.edit.title",
                  edit: true
                }
              }
            ]
          },

          // Toppings
          {
            path: ":restaurant/toppings_addit",
            meta: {
              breadcrumb: "admin.restaurants.toppings_addit.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.restaurants.toppings_addit",
                component: ToppingsAddit,
                meta: {
                  breadcrumb: "admin.restaurants.toppings_addit.title"
                }
              },
              {
                path: "create",
                name: "admin.restaurants.toppings_addit.create",
                component: ToppingAdditForm,
                meta: {
                  breadcrumb: "admin.restaurants.toppings_addit.create.title"
                }
              },
              {
                path: ":topping_addit/edit",
                name: "admin.restaurants.toppings_addit.edit",
                component: ToppingAdditForm,
                meta: {
                  breadcrumb: "admin.restaurants.toppings_addit.edit.title",
                  edit: true
                }
              }
            ]
          },

          // Modifications
          {
            path: ":restaurant/modifications",
            meta: {
              breadcrumb: "admin.restaurants.modifications.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.restaurants.modifications",
                component: Modifications,
                meta: {
                  breadcrumb: "admin.restaurants.modifications.title"
                }
              },
              {
                path: "create",
                name: "admin.restaurants.modifications.create",
                component: ModificationForm,
                meta: {
                  breadcrumb: "admin.restaurants.modifications.create.title"
                }
              },
              {
                path: ":modification/edit",
                name: "admin.restaurants.modifications.edit",
                component: ModificationForm,
                meta: {
                  breadcrumb: "admin.restaurants.modifications.edit.title",
                  edit: true
                }
              }
            ]
          },

          // Accompaniments
          {
            path: ":restaurant/accompaniments",
            meta: {
              breadcrumb: "admin.restaurants.accompaniments.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.restaurants.accompaniments",
                meta: {
                  breadcrumb: "admin.restaurants.accompaniments.title"
                },
                component: Accompaniments
              },
              {
                path: "create",
                name: "admin.restaurants.accompaniments.create",
                meta: {
                  breadcrumb: "admin.restaurants.accompaniments.create.title"
                },
                component: AccompanimentForm
              },
              {
                path: ":accompaniment/edit",
                name: "admin.restaurants.accompaniments.edit",
                component: AccompanimentForm,
                meta: {
                  breadcrumb: "admin.restaurants.accompaniments.edit.title",
                  edit: true
                }
              }
            ]
          },

          // Sections
          {
            path: ":restaurant/sections",
            meta: {
              breadcrumb: "admin.restaurants.sections.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.restaurants.sections",
                component: Sections,
                meta: {
                  breadcrumb: "admin.restaurants.sections.title"
                }
              },
              {
                path: "create",
                name: "admin.restaurants.sections.create",
                component: SectionForm,
                meta: {
                  breadcrumb: "admin.restaurants.sections.create.title"
                }
              },
              {
                path: ":section/edit",
                name: "admin.restaurants.sections.edit",
                component: SectionForm,
                meta: {
                  breadcrumb: "admin.restaurants.sections.edit.title",
                  edit: true
                }
              },
              // Section dishes
              {
                path: ":section/dishes",
                meta: {
                  breadcrumb: "admin.restaurants.sections.dishes.title"
                },
                component: {
                  render(c) {
                    return c("router-view");
                  }
                },
                children: [
                  {
                    path: "",
                    name: "admin.restaurants.sections.dishes",
                    component: Dishes,
                    meta: {
                      breadcrumb: "admin.restaurants.sections.dishes.title"
                    }
                  },
                  {
                    path: "create",
                    name: "admin.restaurants.sections.dishes.create",
                    component: DishForm,
                    meta: {
                      breadcrumb:
                        "admin.restaurants.sections.dishes.create.title"
                    }
                  },
                  {
                    path: ":dish/edit",
                    name: "admin.restaurants.sections.dishes.edit",
                    component: DishForm,
                    meta: {
                      breadcrumb:
                        "admin.restaurants.sections.dishes.edit.title",
                      edit: true
                    }
                  }
                ]
              }
            ]
          },

          // Sliders
          {
            path: ":restaurant/sliders",
            meta: {
              breadcrumb: "admin.restaurants.sliders.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.restaurants.sliders",
                component: Sliders,
                meta: {
                  breadcrumb: "admin.restaurants.sliders.title"
                }
              },
              {
                path: "create",
                name: "admin.restaurants.sliders.create",
                component: SliderForm,
                meta: {
                  breadcrumb: "admin.restaurants.sliders.create.title"
                }
              },
              {
                path: ":slider/edit",
                name: "admin.restaurants.sliders.edit",
                component: SliderForm,
                meta: {
                  breadcrumb: "admin.restaurants.sliders.edit.title",
                  edit: true
                }
              }
            ]
          },

          // Splashes
          {
            path: ":restaurant/splashes",
            meta: {
              breadcrumb: "admin.restaurants.splashes.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.restaurants.splashes",
                component: Splashes,
                meta: {
                  breadcrumb: "admin.restaurants.splashes.title"
                }
              },
              {
                path: "create",
                name: "admin.restaurants.splashes.create",
                component: SplashForm,
                meta: {
                  breadcrumb: "admin.restaurants.splashes.create.title"
                }
              },
              {
                path: ":splash/edit",
                name: "admin.restaurants.splashes.edit",
                component: SplashForm,
                meta: {
                  breadcrumb: "admin.restaurants.splashes.edit.title",
                  edit: true
                }
              }
            ]
          },

          // Deleted items
          {
            path: ":restaurant/deleted-items",
            name: "admin.restaurants.deleted_items",
            component: DeletedItems,
            meta: {
              breadcrumb: "admin.restaurants.deleted_items.title"
            }
          }
        ]
      },

      // Cuisines
      {
        path: "catalogue/cuisines",
        meta: {
          breadcrumb: "admin.catalogue.cuisines.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.catalogue.cuisines",
            component: Cuisines,
            meta: {
              breadcrumb: "admin.catalogue.cuisines.title"
            }
          },
          {
            path: "create",
            name: "admin.cuisines.create",
            component: CuisineForm,
            meta: {
              breadcrumb: "admin.catalogue.cuisines.create.title"
            }
          },
          {
            path: ":id/edit",
            name: "admin.cuisines.edit",
            component: CuisineForm,
            meta: {
              breadcrumb: "admin.catalogue.cuisines.edit.title",
              edit: true
            }
          }
        ]
      },

      // Filters
      {
        path: "catalogue/filters",
        meta: {
          breadcrumb: "admin.catalogue.filters.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.catalogue.filters",
            component: Filters,
            meta: {
              breadcrumb: "admin.catalogue.filters.title"
            }
          },
          {
            path: "create",
            name: "admin.filters.create",
            component: FilterForm,
            meta: {
              breadcrumb: "admin.catalogue.filters.create.title"
            }
          },
          {
            path: ":id/edit",
            name: "admin.filters.edit",
            component: FilterForm,
            meta: {
              breadcrumb: "admin.catalogue.filters.edit.title",
              edit: true
            }
          }
        ]
      },

      // Taxes
      {
        path: "catalogue/taxes",
        meta: {
          breadcrumb: "admin.catalogue.taxes.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.catalogue.taxes",
            component: Taxes,
            meta: {
              breadcrumb: "admin.catalogue.taxes.title"
            }
          },
          {
            path: "create",
            name: "admin.taxes.create",
            component: TaxForm,
            meta: {
              breadcrumb: "admin.catalogue.taxes.create.title"
            }
          },
          {
            path: ":id/edit",
            name: "admin.taxes.edit",
            component: TaxForm,
            meta: {
              breadcrumb: "admin.catalogue.taxes.edit.title",
              edit: true
            }
          }
        ]
      },

      // Types
      {
        path: "catalogue/types",
        meta: {
          breadcrumb: "admin.catalogue.types.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.catalogue.types",
            component: Types,
            meta: {
              breadcrumb: "admin.catalogue.types.title"
            }
          },
          {
            path: "create",
            name: "admin.types.create",
            component: TypeForm,
            meta: {
              breadcrumb: "admin.catalogue.types.create.title"
            }
          },
          {
            path: ":id/edit",
            name: "admin.types.edit",
            component: TypeForm,
            meta: {
              breadcrumb: "admin.catalogue.types.edit.title",
              edit: true
            }
          }
        ]
      },

      // Packages
      {
        path: "catalogue/packages",
        meta: {
          breadcrumb: "admin.catalogue.packages.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.catalogue.packages",
            component: Packages,
            meta: {
              breadcrumb: "admin.catalogue.packages.title"
            }
          },
          {
            path: "create",
            name: "admin.packages.create",
            component: PackageForm,
            meta: {
              breadcrumb: "admin.catalogue.packages.create.title"
            }
          },
          {
            path: ":id/edit",
            name: "admin.packages.edit",
            component: PackageForm,
            meta: {
              breadcrumb: "admin.catalogue.packages.edit.title",
              edit: true
            }
          }
        ]
      },

      // Orders
      {
        path: "orders",
        meta: {
          breadcrumb: "admin.orders.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "delivery",
            name: "admin.orders.delivery",
            component: Orders,
            meta: {
              breadcrumb: "admin.delivery",
              pickup: false
            }
          },
          {
            path: "pickup",
            name: "admin.orders.pickup",
            component: Orders,
            meta: {
              breadcrumb: "admin.pickup",
              pickup: true
            }
          },
          {
            path: "with-comments",
            name: "admin.orders.with_comments",
            component: Orders,
            meta: {
              breadcrumb: "admin.with_comments",
              with_comments: true
            }
          },
          {
            path: "cash",
            name: "admin.orders.cash",
            component: Orders,
            meta: {
              breadcrumb: "admin.cash",
              cash: true
            }
          },
          {
            path: "canceled",
            name: "admin.orders.canceled",
            component: Orders,
            meta: {
              breadcrumb: "admin.canceled",
              canceled: true
            }
          },
          {
            path: ":order",
            name: "admin.orders.show",
            component: OrderForm,
            meta: {
              breadcrumb: "admin.orders.show.title"
            }
          }
        ]
      },

      // Users
      {
        path: "users",
        meta: {
          breadcrumb: "admin.users.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.users",
            component: Users,
            meta: {
              breadcrumb: "admin.users.title"
            }
          },
          {
            path: ":user/show",
            name: "admin.users.show",
            component: UserForm,
            meta: {
              breadcrumb: "admin.users.show.title"
            }
          },
          {
            path: ":user/neworder",
            name: "admin.users.neworder",
            component: UserNewOrder,
            meta: {
              breadcrumb: "admin.users.neworder"
            }
          }
        ]
      },

      // Couriers
      {
        path: "couriers",
        meta: {
          breadcrumb: "admin.couriers.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.couriers",
            component: Couriers,
            meta: {
              breadcrumb: "admin.couriers.title"
            }
          },
          {
            path: "create",
            name: "admin.couriers.create",
            component: CourierForm,
            meta: {
              breadcrumb: "admin.couriers.create.title"
            }
          },
          {
            path: ":id/edit",
            name: "admin.couriers.edit",
            component: CourierForm,
            meta: {
              breadcrumb: "admin.couriers.edit.title",
              edit: true
            }
          }
        ]
      },

      {
        path: "intervals",
        meta: {
          breadcrumb: "admin.intervals.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "delivery",
            name: "admin.intervals.delivery",
            component: Intervals,
            meta: {
              breadcrumb: "admin.delivery"
            }
          },
          {
            path: "pickup",
            name: "admin.intervals.pickup",
            component: Intervals,
            meta: {
              breadcrumb: "admin.pickup",
              pickup: true
            }
          }
        ]
      },

      // Statistics
      {
        path: "statistics/delivery",
        meta: {
          breadcrumb: "management.statistics.delivery",
          middleware: [isSuperAdmin]
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "restaurants",
            name: "admin.statistics.delivery.restaurants",
            component: StatisticsRestaurants,
            meta: {
              breadcrumb: "management.statistics.restaurants.title"
            }
          },
          {
            path: "orders",
            name: "admin.statistics.delivery.orders",
            component: StatisticsOrders,
            meta: {
              breadcrumb: "management.statistics.orders.title"
            }
          },
          {
            path: "tips",
            name: "admin.statistics.delivery.tips",
            component: StatisticsTips,
            meta: {
              breadcrumb: "management.statistics.tips.title"
            }
          },
          {
            path: "amex",
            name: "admin.statistics.delivery.amex",
            component: StatisticsAmex,
            meta: {
              breadcrumb: "management.statistics.amex.title"
            }
          },
          {
            path: "package-balances",
            name: "admin.statistics.delivery.package_balances",
            component: StatisticsPackageBalances,
            meta: {
              breadcrumb: "management.statistics.package_balances.title"
            }
          },
          {
            path: "accounting",
            name: "admin.statistics.delivery.accounting",
            component: StatisticsAccounting,
            meta: {
              breadcrumb: "management.statistics.accounting.title"
            }
          },
          {
            path: "general",
            name: "admin.statistics.delivery.general",
            component: StatisticsGeneral,
            meta: {
              breadcrumb: "management.statistics.general.title"
            }
          },
          {
            path: "purchases",
            name: "admin.statistics.delivery.purchases",
            component: StatisticsPurchases,
            meta: {
              breadcrumb: "management.statistics.purchases.title"
            }
          },
          {
            path: "purchased-orders",
            name: "admin.statistics.delivery.purchased_orders",
            component: StatisticsPurchasedOrders,
            meta: {
              breadcrumb: "management.statistics.purchased_orders.title"
            }
          },
          {
            path: "delivered-orders",
            name: "admin.statistics.delivery.delivered_orders",
            component: StatisticsDeliveredOrders,
            meta: {
              breadcrumb: "management.statistics.delivered_orders.title"
            }
          },
          {
            path: "cancelled-orders",
            name: "admin.statistics.delivery.cancelled_orders",
            component: StatisticsCancelledOrders,
            meta: {
              breadcrumb: "management.statistics.cancelled_orders.title"
            }
          }
        ]
      },
      {
        path: "statistics/pickup",
        meta: {
          breadcrumb: "management.statistics.pickup",
          middleware: [isSuperAdmin]
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "restaurants",
            name: "admin.statistics.pickup.restaurants",
            component: StatisticsRestaurants,
            meta: {
              breadcrumb: "management.statistics.restaurants.title",
              pickup: true
            }
          },
          {
            path: "orders",
            name: "admin.statistics.pickup.orders",
            component: StatisticsOrders,
            meta: {
              breadcrumb: "management.statistics.orders.title",
              pickup: true
            }
          },
          {
            path: "tips",
            name: "admin.statistics.pickup.tips",
            component: StatisticsTips,
            meta: {
              breadcrumb: "management.statistics.tips.title",
              pickup: true
            }
          },
          {
            path: "amex",
            name: "admin.statistics.pickup.amex",
            component: StatisticsAmex,
            meta: {
              breadcrumb: "management.statistics.amex.title",
              pickup: true
            }
          },
          {
            path: "package-balances",
            name: "admin.statistics.pickup.package_balances",
            component: StatisticsPackageBalances,
            meta: {
              breadcrumb: "management.statistics.package_balances.title",
              pickup: true
            }
          },
          {
            path: "accounting",
            name: "admin.statistics.pickup.accounting",
            component: StatisticsAccounting,
            meta: {
              breadcrumb: "management.statistics.accounting.title",
              pickup: true
            }
          },
          {
            path: "general",
            name: "admin.statistics.pickup.general",
            component: StatisticsGeneral,
            meta: {
              breadcrumb: "management.statistics.general.title",
              pickup: true
            }
          },
          {
            path: "purchases",
            name: "admin.statistics.pickup.purchases",
            component: StatisticsPurchases,
            meta: {
              breadcrumb: "management.statistics.purchases.title",
              pickup: true
            }
          },
          {
            path: "purchased-orders",
            name: "admin.statistics.pickup.purchased_orders",
            component: StatisticsPurchasedOrders,
            meta: {
              breadcrumb: "management.statistics.purchased_orders.title",
              pickup: true
            }
          },
          {
            path: "delivered-orders",
            name: "admin.statistics.pickup.delivered_orders",
            component: StatisticsDeliveredOrders,
            meta: {
              breadcrumb: "management.statistics.delivered_orders.title",
              pickup: true
            }
          },
          {
            path: "cancelled-orders",
            name: "admin.statistics.pickup.cancelled_orders",
            component: StatisticsCancelledOrders,
            meta: {
              breadcrumb: "management.statistics.cancelled_orders.title",
              pickup: true
            }
          }
        ]
      },
      {
        path: "statistics/general",
        meta: {
          breadcrumb: "management.statistics.generals",
          middleware: [isSuperAdmin]
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "coupons",
            name: "admin.statistics.coupons",
            component: StatisticsCoupons,
            meta: {
              breadcrumb: "management.statistics.coupons.title"
            }
          },
          {
            path: "balances",
            name: "admin.statistics.balance",
            component: StatisticsBalances,
            meta: {
              breadcrumb: "management.statistics.balances.title"
            }
          },
          {
            path: "user_balances",
            name: "admin.statistics.user_balance",
            component: StatisticsUserBalances,
            meta: {
              breadcrumb: "management.statistics.user_balances.title"
            }
          },
          {
            path: "balance_on_date",
            name: "admin.statistics.balance_on_date",
            component: StatisticsUserBalancesOnDate,
            meta: {
              breadcrumb: "management.statistics.user_balances_on_date.title"
            }
          },
          {
            path: "payrestaurants",
            name: "admin.statistics.payrestaurants",
            component: StatisticsPayRestaurants,
            meta: {
              breadcrumb: "management.statistics.payRestaurants.title"
            }
          },
          {
            path: "global_stat",
            name: "admin.statistics.global_stat",
            component: StatisticsGlobal,
            meta: {
              breadcrumb: "management.statistics.global.title"
            }
          },
          {
            path: "dishes_rest",
            name: "admin.statistics.dishes_rest",
            component: StatisticsDishesRest,
            meta: {
              breadcrumb: "management.statistics.dishes_rest.title"
            }
          }
        ]
      },
      // Extra shop
      {
        path: "extra_shop",
        name: "admin.extra_shop",
        redirect: { name: "admin.extra_shop.categories" }
      },
      {
        path: "extra_shop/categories",
        meta: {
          breadcrumb: "admin.extra_shop.categories.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.extra_shop.categories",
            component: ExtraShopCategories,
            meta: {
              breadcrumb: "admin.extra_shop.categories.title"
            }
          },
          {
            path: "create",
            name: "admin.extra_shop.categories.create",
            component: ExtraShopCategoryForm,
            meta: {
              breadcrumb: "admin.extra_shop.categories.create.title"
            }
          },
          {
            path: ":category/edit",
            name: "admin.extra_shop.categories.edit",
            component: ExtraShopCategoryForm,
            meta: {
              breadcrumb: "admin.extra_shop.categories.edit.title",
              edit: true
            }
          },
          // Extra shop category dishes
          {
            path: ":category/dishes",
            meta: {
              breadcrumb: "admin.extra_shop.categories.dishes.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.extra_shop.categories.dishes",
                component: ExtraShopDishes,
                meta: {
                  breadcrumb: "admin.extra_shop.categories.dishes.title"
                }
              },
              {
                path: "create",
                name: "admin.extra_shop.categories.dishes.create",
                component: ExtraShopDishForm,
                meta: {
                  breadcrumb: "admin.extra_shop.categories.dishes.create.title"
                }
              }
            ]
          }
        ]
      },

      // Coupons
      {
        path: "coupons",
        meta: {
          breadcrumb: "admin.coupons.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.coupons",
            component: Coupons,
            meta: {
              breadcrumb: "admin.coupons.title"
            }
          },
          {
            path: "create",
            name: "admin.coupons.create",
            component: CouponsForm,
            meta: {
              breadcrumb: "admin.coupons.create.title"
            }
          },
          {
            path: "edit",
            name: "admin.coupons.edit",
            component: CouponsForm,
            meta: {
              breadcrumb: "admin.coupons.edit.title"
            }
          }
        ]
      },

      // Package balances
      {
        path: "package-balances",
        meta: {
          breadcrumb: "admin.package_balances.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "admin.package_balances",
            component: PackageBalances,
            meta: {
              breadcrumb: "admin.package_balances.title"
            }
          },
          {
            path: "edit",
            name: "admin.package_balances.edit",
            component: PackageBalanceForm,
            meta: {
              breadcrumb: "admin.package_balances.edit.title"
            }
          }
        ]
      },

      // Notifications
      {
        path: "notifications",
        meta: {
          breadcrumb: "admin.notifications.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "dishes",
            name: "admin.notifications.dishes",
            component: NotificationsDishes,
            meta: {
              breadcrumb: "admin.notifications.dishes.title"
            }
          },
          {
            path: "stock",
            name: "admin.notifications.stock",
            component: NotificationsStock,
            meta: {
              breadcrumb: "admin.notifications.stock.title"
            }
          },
          {
            path: "restaurants",
            name: "admin.notifications.restaurants",
            component: NotificationsRestaurants,
            meta: {
              breadcrumb: "admin.notifications.restaurants.title"
            }
          }
        ]
      },

      // Settings
      {
        path: "settings",
        meta: {
          breadcrumb: "admin.settings.title"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "general",
            name: "admin.settings.general",
            component: SettingsGeneral,
            meta: {
              breadcrumb: "admin.settings.general.title"
            }
          },
          {
            path: "hero",
            name: "admin.settings.hero",
            component: SettingsHero,
            meta: {
              breadcrumb: "admin.settings.hero.title"
            }
          },
          {
            path: "slider",
            meta: {
              breadcrumb: "admin.settings.slider.title"
            },
            component: {
              render(c) {
                return c("router-view");
              }
            },
            children: [
              {
                path: "",
                name: "admin.settings.slider",
                component: SettingsSlider,
                meta: {
                  breadcrumb: "admin.settings.slider.title"
                }
              },
              {
                path: "create",
                name: "admin.settings.slider.create",
                component: SettingsSliderForm,
                meta: {
                  breadcrumb: "admin.settings.slider.create.title"
                }
              },
              {
                path: ":slider/edit",
                name: "admin.settings.slider.edit",
                component: SettingsSliderForm,
                meta: {
                  breadcrumb: "admin.settings.slider.edit.title",
                  edit: true
                }
              }
            ]
          }
        ]
      }
    ]
  }
];
